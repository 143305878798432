//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
export default {
  props: {
    title_name: {
      type: String,
      default: "",
    },
    title_name2: {
      type: String,
      default: "",
    },
    load: {
      type: Boolean,
      required: false,
    },
    childLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState(["mobile"]),
  },
  methods: {
    ...mapActions(["mobile_ToggleLeft"]),
    toggleBvLeft() {
      // this.isOpen = !this.isOpen;
      this.mobile_ToggleLeft(!this.mobile.showLeft);
    },
    updata(val) {
      this.$emit("updata", val);
    },
  },
};
